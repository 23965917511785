import { formatDate } from '@/helper/date'
import { t } from '@lingui/macro'

export function FiatRecordDetail({ data }) {
  const { createdByTime, updatedByTime } = data
  return (
    <div className="fiat-detail px-4">
      <div className="detail-item">
        <div className="label">{t`assets.financial-record.creationTime`}</div>
        <div className="value">{createdByTime ? formatDate(createdByTime) : '--'}</div>
      </div>
      <div className="detail-item">
        <div className="label">{t`assets.financial-record.completionTime`}</div>
        <div className="value">{updatedByTime ? formatDate(updatedByTime) : '--'}</div>
      </div>
    </div>
  )
}
