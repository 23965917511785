/**
 * 带单分润/分润退回/返佣
 */
import { t } from '@lingui/macro'
import {} from '@/constants/order'
import { useAssetsStore } from '@/store/assets'
import { CreateTimeItem } from '../common/create-time-item'

export function CopyTradingRebateDetails() {
  const { financialRecordDetail } = useAssetsStore()
  const { projectName = '--' } = { ...financialRecordDetail }

  return (
    <>
      <div className="details-item-info">
        <div className="label">{t`features_assets_financial_record_record_detail_record_details_info_derivative_log_details_index_2k9v13mi7k`}</div>
        <div className="value">{projectName || '--'}</div>
      </div>
      <CreateTimeItem cssName="details-item-info" />
    </>
  )
}
